import styled from "styled-components"

import { media } from "src/uikit"

export const Text = styled.p`
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0a1c61;
  margin: 0;

  @media ${media.mobile} {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.4px;
  }
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 12px;
`

export const ListItem = styled.li`
  display: flex;

  margin: 12px 0;

  :first-child {
    margin-top: 0;
  }

  :last-child {
    margin-bottom: 0;
  }

  .circle-container {
    display: flex;
    margin-top: 13px;
    margin-right: 8px;
  }

  .circle {
    background: #313c9d;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
`
