import styled from 'styled-components';

export const Container = styled.section`
  padding: 3rem 0;
  background: #f8f9fc;
`;

export const ScrollContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.08));

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 1.15rem;
  font-weight: 600;
  color: #1a1f36;
  letter-spacing: -0.01em;
  margin: 0 0 0.75rem;
`;

export const Description = styled.p`
  font-size: 0.9rem;
  color: #4f566b;
  line-height: 1.5;
  margin: 0;
`; 