import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.div`
  padding: 0 16px;

  .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    max-height: 324px;
    position: relative;
    background: #f2f2f9;
    border-radius: 32px;
    padding-right: 80px;
  }

  img {
    max-width: 322px;
    position: relative;
    top: -77px;
    border-radius: 0 0 0 32px;
    margin-right: 67px;
  }

  .text-container {
    align-self: center;
  }

  .author {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.8px;
    color: #142470;
    margin: 0;
    margin-bottom: 24px;
  }

  .quote {
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;
    letter-spacing: 0.4px;
    color: #142470;
    margin: 0;
  }

  @media (max-width: 1100px) {
    .inner {
      max-width: 688px;
      max-height: 252px;
      padding-right: 24px;
    }

    img {
      max-width: 252px;
      height: 313px;
      top: -61px;
      margin-right: 18px;
    }

    .author {
      font-size: 17px;
      line-height: 32px;
      letter-spacing: 0.5px;
      margin-bottom: 4px;
    }

    .quote {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.4px;
    }
  }

  @media ${media.mobile} {
    .inner {
      flex-direction: column;
      max-height: 100%;
      max-width: 100%;
      padding: 0;
    }

    .text-container {
      order: 1;
      margin: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 12px;
      max-width: 256px;
    }

    .author,
    .quote {
      width: 100%;
    }

    img {
      order: 2;
      top: 0;
      max-width: 210px;
      height: 262px;
    }
  }
`
