import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useLocale } from "src/common/hooks"
import { Title, Indent } from "src/uikit"
import Button from "src/newUikit/Button"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import { getModalClickHandler } from "src/components/ModalsContainer/events"

import * as S from "./styled"

const BottomCTA = () => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query {
      allContentfulHomepageB5BottomCta {
        nodes {
          ctaButtonTitle
          ctaButtonModal
          ctaButtonModalTitle
          description
          image {
            fluid {
              src
              srcSet
            }
            description
          }
          tabletImage {
            fluid {
              src
              srcSet
            }
            description
          }
          mobileImage {
            fluid {
              src
              srcSet
            }
            description
          }
          title
          node_locale
        }
      }
    }
  `)

  const node = useMemo(
    () => getNodeByLocale(data.allContentfulHomepageB5BottomCta.nodes, locale),
    [data.allContentfulHomepageB5BottomCta.nodes, locale]
  )

  const handleClick = getModalClickHandler(node.ctaButtonModal, {
    title: node.ctaButtonModalTitle,
  })

  return (
    <S.Container>
      <Indent lg={160} md={80} sm={64} />
      <div className="inner">
        <Title>{node.title}</Title>
        <Indent lg={80} md={40} sm={32} />
        <img
          className="desktop lazyload"
          data-src={node.image?.fluid?.src}
          data-srcset={node.image?.fluid?.srcSet}
          alt={node.image.description}
        />
        <img
          className="tablet lazyload"
          data-src={node.tabletImage?.fluid?.src}
          data-srcset={node.tabletImage?.fluid?.srcSet}
          alt={node.tabletImage?.description}
        />
        <img
          className="mobile lazyload"
          data-src={node.mobileImage?.fluid?.src}
          data-srcset={node.mobileImage?.fluid?.srcSet}
          alt={node.mobileImage?.description}
        />
        <Indent lg={80} md={40} sm={32} />
        <S.Description>{node.description}</S.Description>
        <Indent lg={24} md={24} sm={24} />
        <Button color="purple" onClick={handleClick}>
          {node.ctaButtonTitle}
        </Button>
      </div>
      <Indent lg={120} md={80} sm={64} />
    </S.Container>
  )
}

export default BottomCTA
