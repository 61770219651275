import styled from "styled-components"

import { media } from "src/uikit"

export const Headline = styled.h1`
  font-weight: 800;
  font-size: 50px;
  line-height: 64px;
  color: #fff;
  margin-top: 24px;
  margin-bottom: 40px;

  span {
    color: #5ce0dc;
  }

  @media ${media.mobile} {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 32px;
  }
`
