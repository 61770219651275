import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useLocale } from "src/common/hooks"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import { Indent } from "src/uikit"

import * as S from "./styled"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => children,
  },
}

const Testimonial = () => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query {
      allContentfulHomepageB4Testimonial {
        nodes {
          node_locale
          testimonialPhoto1 {
            file {
              url
            }
            description
          }
          testimonialText1 {
            json
          }
          nameAndTitle
        }
      }
    }
  `)

  const locales = useMemo(
    () =>
      getNodeByLocale(data.allContentfulHomepageB4Testimonial.nodes, locale),
    [data.allContentfulHomepageB4Testimonial.nodes, locale]
  )

  return (
    <S.Container>
      <Indent lg={76} md={41} sm={0} />
      <div className="inner">
        <img
          className="lazyload"
          data-src={locales.testimonialPhoto1.file.url}
          alt={locales.testimonialPhoto1.description}
        />
        <div className="text-container">
          <p className="author">{locales.nameAndTitle}</p>
          <blockquote className="quote">
            {documentToReactComponents(locales.testimonialText1.json, options)}
          </blockquote>
        </div>
      </div>
    </S.Container>
  )
}

export default Testimonial
