import styled from "styled-components"

import { Title, media } from "src/uikit"

export const Container = styled.section`
  .inner {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img.desktop {
    display: block;
  }

  img.tablet,
  img.mobile {
    display: none;
  }

  img {
    max-width: 100%;
  }

  ${Title} {
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #0a1c61;
    text-align: center;
  }

  @media ${media.tablet} {
    .inner {
      padding: 0 40px;
    }

    img.desktop {
      display: none;
    }

    img.tablet {
      display: block;
    }
  }

  @media ${media.mobile} {
    .inner {
      padding-left: 16px;
      padding-right: 16px;
    }

    ${Title} {
      font-size: 26px;
      line-height: 32px;
    }

    img.tablet {
      display: none;
    }

    img.mobile {
      display: block;
    }

    button {
      width: 100%;
    }
  }
`

export const Description = styled.p`
  font-weight: normal;
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0a1c61;
  margin: 0;
  text-align: center;
`
