import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.section`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 6px 10px rgba(39, 49, 142, 0.1);
  border-radius: 24px;
  position: relative;
  z-index: 100;

  a:hover {
    p {
      text-decoration: underline;
      color: #5855f4;
    }
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .quote-icon {
    width: 44px;
    height: 28px;

    path {
      fill: #9da7d8;
    }
  }

  .left-quote-icon {
    align-self: flex-start;
  }

  .right-quote-icon {
    align-self: flex-end;
  }

  .quote-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 64px;
  }

  @media ${media.maxDesktop} {
    max-width: 800px;

    a:hover {
      p {
        color: #142470;
      }
    }
  }

  @media ${media.tablet} {
    max-width: 688px;

    a {
      padding: 32px;
    }

    .inner {
      padding: 0;
    }
  }

  @media ${media.mobile} {
    max-width: 288px;

    .quote-container {
      flex-direction: column;
      margin-bottom: 10px;
    }

    .text {
      margin: 8px 0;
    }

    .quote-icon {
      width: 16px;
      height: 14px;
    }

    .logo {
      margin-bottom: 16px;
    }

    a {
      padding: 16px;
    }
  }
`

export const Paragraph = styled.p`
  margin: 0 32px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #142470;
  max-width: 720px;

  @media ${media.tablet} {
    max-width: 477px;
    text-decoration: none !important;
  }

  @media ${media.mobile} {
    max-width: 256px;
    margin: 0;
  }
`

export const Author = styled.p`
  position: absolute;
  bottom: 30px;
  right: 64px;
  margin: 0;
  display: flex;
  align-items: center;

  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0a1c61 !important;
  text-decoration: none !important;

  svg {
    display: none;
    margin-left: 4px;
  }

  @media ${media.tablet} {
    bottom: 22px;
    right: 32px;

    svg {
      display: block;
    }
  }

  @media ${media.mobile} {
    position: relative;
    bottom: 0;
    right: 0;
  }
`
