import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { useLocale } from "src/common/hooks"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import LeftQuoteIcon from "src/images/company/leftQuote.svg"
import RightQuoteIcon from "src/images/company/rightQuote.svg"
import TechCrunchLogo from "src/images/logos/tech-crunch-color.svg"
import { Indent } from "src/uikit"

import ArrowRightIcon from "./assets/arrow-right.svg"
import * as S from "./styled"

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <S.Paragraph>{children}</S.Paragraph>
    ),
  },
}

const Quote = () => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query {
      locales: allContentfulHomepageB1 {
        nodes {
          node_locale
          b1QuoteText {
            json
          }
          b1QuoteAuthor
          b1QuoteLink
        }
      }
    }
  `)

  const locales = getNodeByLocale(data.locales.nodes, locale)

  return (
    <>
      <Indent lg={-83} md={-81} sm={-61} />
      <S.Container id="partners">
        <div className="inner">
          <a
            href={locales.b1QuoteLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="quote-container">
              <LeftQuoteIcon className="quote-icon left-quote-icon" />
              <div className="text">
                {documentToReactComponents(locales.b1QuoteText.json, options)}
              </div>
              <RightQuoteIcon className="quote-icon right-quote-icon" />
            </div>
            <TechCrunchLogo className="logo" />
            <S.Author>
              {locales.b1QuoteAuthor} <ArrowRightIcon />
            </S.Author>
          </a>
        </div>
      </S.Container>
    </>
  )
}

export default Quote
