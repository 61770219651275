import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.section`
  background: linear-gradient(249.71deg, #7785ff -0.91%, #6472ef 102.89%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 600px;

  overflow-x: hidden;
  overflow-y: hidden;

  .inner {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }

  .text-container {
    max-width: 520px;
    margin-right: 60px;
    z-index: 2;

    button:first-child {
    }
  }

  .buttons-container {
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;

    button:first-child {
      margin-bottom: 12px;
    }
  }

  .video-container {
    display: flex;
    align-items: center;
    max-width: 560px;
    margin-left: 60px;
    z-index: 2;
    position: relative;
  }

  .bg-1 {
    position: absolute;
    top: -580px;
    left: -403px;
  }

  .bg-2 {
    position: absolute;
    bottom: -586px;
    right: -527px;
  }

  @media (max-width: 1246px) {
    .inner {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .text-container {
      max-width: 688px;
      margin: 0;
      margin-bottom: 64px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .buttons-container {
      max-width: 100%;
    }

    .video-container {
      margin: 0;
      margin-bottom: 190px;
      max-height: 366px;
    }

    .bg-1 {
      top: -526px;
      left: -523px;
    }

    .bg-2 {
      display: none;
    }
  }

  @media ${media.mobile} {
    padding-left: 16px;
    padding-right: 16px;

    .text-container {
      margin-bottom: 48px;
    }

    .video-container {
      margin-bottom: 170px;
      max-height: 188px;
    }

    .buttons-container {
      > button,
      > a {
        width: 100%;
      }
    }

    .bg-1 {
      top: -577px;
      left: -574px;
    }
  }
`

export const Background = styled.div`
  position: absolute;
  max-width: 478px;
  width: 100%;
  height: 366px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #5855f4;
  border-radius: 8px;
  z-index: 1;

  @media ${media.mobile} {
    max-width: 246px;
    height: 110%;
    border-radius: 4px;
  }
`

export const PartnerContainer = styled.div`
  position: absolute;
  bottom: -50px;
  left: -150px;
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid rgba(255, 255, 255, 0.16);
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 107px;
  }

  .tablet,
  .mobile {
    display: none;
  }

  @media (max-width: 1246px) {
    bottom: -70px;
    left: 0;
    width: 100%;
    height: auto;
    border: none;

    .inner {
      flex-direction: row;
      align-items: center;
      max-width: 100%;
    }

    .tablet {
      display: block;
    }

    .desktop {
      display: none;
    }
  }

  @media ${media.mobile} {
    .mobile {
      display: block;
    }

    .tablet {
      display: none;
    }

    img {
      margin-right: 10px;
    }
  }
`
