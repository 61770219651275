import React, { useState, useRef, useEffect } from "react"

import PlayIcon from "./assets/play.svg"
import * as S from "./styled"

const VideoPlayer = ({ src, contentType, poster }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const el = useRef(null)

  useEffect(() => {
    if (!el.current) {
      return
    }

    const { current } = el

    const handleEnded = () => {
      current.pause()
      setIsPlaying(false)
    }

    current.addEventListener("ended", handleEnded)

    return () => {
      current.removeEventListener("endede", handleEnded)
    }
  }, [])

  const handlePlay = async () => {
    if (!el.current) {
      return
    }

    if (!el.current.paused) {
      el.current.pause()
      setIsPlaying(false)
      return
    }

    try {
      await el.current.play()
      setIsPlaying(true)
    } catch (err) {
      // console.log('err: ', err)
    }
  }

  return (
    <S.Container>
      <video ref={el} muted poster={poster} onClick={handlePlay}>
        <source src={src} type={contentType} />
      </video>
      {!isPlaying && (
        <S.Button type="button" onClick={handlePlay}>
          <PlayIcon />
        </S.Button>
      )}
    </S.Container>
  )
}

export default VideoPlayer
