import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  .inner {
    width: 1000px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner.mobile {
    display: none;

    flex-direction: column;
    align-items: center;
  }

  .logos-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 237px;
    margin-bottom: 30px;

    :last-child {
      margin-bottom: 0;
    }
  }

  .box {
    min-width: 171px;
    min-height: 64px;
    max-width: 171px;
    max-height: 64px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .colorful {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

    :hover {
      .colorful {
        opacity: 1;
        visibility: visible;
        display: block;
      }

      .monochrome {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }
  }

  @media (max-width: 1200px) {
    .inner {
      width: 688px;
      height: auto;
      flex-wrap: wrap;
      padding: 0;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 173px;
      margin: 24px 0;
    }

    ${props => {
      return (
        props.logosCount % 2 !== 0 &&
        `
          .box:nth-child(1) {
            margin-left: 24px;
          }

          .box:nth-child(3) {
            margin-right: 24px;
          }
        `
      )
    }}
  }

  @media ${media.mobile} {
    padding-left: 16px;
    padding-right: 16px;

    .inner {
      max-width: 100%;
    }

    .box {
      min-width: 96px;
      max-width: 96px;
      min-height: 40px;
      max-height: 40px;
      flex-basis: 45%;
      margin: 16px 0;
    }

    .box:nth-child(1),
    .box:nth-child(3) {
      margin: 16px 0;
    }
  }
`

export const Logo = styled.img`
  max-width: 100%;
  transition: 0.3s;
  display: block;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media (max-width: 1100px) {
    margin: 0;
  }

  @media ${media.mobile} {
    max-height: 34px;
    max-width: 80px;
  }
`
