import styled from "styled-components"

import { Title, media } from "src/uikit"

export const Container = styled.section`
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;

  :nth-child(2n + 1) {
    background-color: #f9f9fd;

    .text-container {
      margin-left: 0;
      margin-right: 40px;
      order: 1;
    }

    .image-container {
      margin-right: 0;
      margin-left: 40px;
      order: 2;
    }
  }

  .inner {
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-container {
    width: 540px;

    margin-left: 40px;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    margin-right: 40px;
  }

  .player-container {
    width: 295px;
  }

  img {
    max-width: 100%;
  }

  ${Title} {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.4px;
    color: #0a1c61;
    font-weight: bold;
  }

  @media (max-width: 1100px) {
    :nth-child(2n + 1) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .inner {
      flex-direction: column;
    }

    .text-container {
      width: 608px;
      order: 1;
      margin: 0;
      margin-bottom: 40px;
    }

    .image-container {
      max-width: 381px;
      order: 2;
      margin: 0;
    }
  }

  @media ${media.mobile} {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 64px;
    padding-bottom: 64px;

    ${Title} {
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.2px;
    }

    .text-container,
    .image-container {
      margin: 0 !important;
    }

    .text-container {
      width: 100%;
      margin-bottom: 40px !important;

      button,
      a {
        width: 100%;
      }
    }

    .image-container {
      max-width: 100%;
    }
  }
`
