import React from 'react';
import * as S from './styled';

const CompactFeaturesSection = ({ content }) => {
  return (
    <S.Container>
      <S.ScrollContainer>
        <S.Grid>
          {content.items.slice(0, 5).map((feature, index) => (
            <S.Card key={index}>
              <S.IconWrapper>
                <img src={feature.img} alt={feature.title} />
              </S.IconWrapper>
              <S.ContentWrapper>
                <S.Title>{feature.title}</S.Title>
                <S.Description>{feature.description}</S.Description>
              </S.ContentWrapper>
            </S.Card>
          ))}
        </S.Grid>
      </S.ScrollContainer>
    </S.Container>
  );
};

export default CompactFeaturesSection; 