import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.div`
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  max-height: 334px;
  height: 100%;
  z-index: 2;

  video {
    width: 100%;
    height: auto;
    position: relative;
    left: 0px;
    top: 0px;
    opacity: 1;
    cursor: pointer;
  }

  @media ${media.tablet} {
    max-height: 366px;
  }

  @media ${media.mobile} {
    display: flex;
    max-height: 188px;
    border-radius: 10px;
  }
`

export const Button = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  background-color: #5855f4;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(44, 43, 95, 0.25);

  :hover {
    background-color: #6f6cfa;
  }

  :active {
    background-color: #4b48f2;
  }

  :disabled {
    background-color: #9794f2;
  }

  svg {
    margin-left: 6px;
  }

  @media ${media.mobile} {
    width: 56px;
    height: 56px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`
