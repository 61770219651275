import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Indent } from "src/uikit"
import { useLocale } from "src/common/hooks"
import { getNodeByLocale } from "src/common/getNodesByLocale"

import * as S from "./styled"

const Partners = () => {
  const locale = useLocale()

  const data = useStaticQuery(graphql`
    query {
      allContentfulHomepageB1 {
        nodes {
          logos {
            id
            file {
              url
            }
            description
          }
          colorfulLogos {
            id
            file {
              url
            }
            description
          }
          node_locale
        }
      }
    }
  `)

  const locales = useMemo(
    () => getNodeByLocale(data.allContentfulHomepageB1.nodes, locale),
    [data.allContentfulHomepageB1.nodes, locale]
  )

  return (
    <S.Container logoCounts={locales.logos.length}>
      <Indent lg={80} md={80} sm={64} />
      <div className="inner">
        {locales.logos.map(logo => (
          <div key={logo?.id} className="box">
            <S.Logo
              className="lazyload"
              data-src={logo?.file?.url}
              alt={logo?.description}
            />
          </div>
        ))}
      </div>
    </S.Container>
  )
}

export default Partners
