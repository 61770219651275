/* eslint-disable react/display-name */

import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import * as S from "./styled"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <S.Text>{children}</S.Text>,
    [BLOCKS.UL_LIST]: (node, children) => <S.List>{children}</S.List>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <S.ListItem>
        <span className="circle-container">
          <span className="circle" />
        </span>{" "}
        {children}
      </S.ListItem>
    ),
  },
}

const Description = ({ json }) => documentToReactComponents(json, options)

export default Description
