import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useLocale } from "src/common/hooks"
import { getNodesByLocale, getNodeByLocale } from "src/common/getNodesByLocale"
import { Indent, Title } from "src/uikit"

import CheckIcon from "./assets/check.svg"
import * as S from "./styled"

const Cases = () => {
  const locale = useLocale()

  const data = useStaticQuery(graphql`
    query Headline {
      allContentfulHomepageB1 {
        nodes {
          b2Headline
          node_locale
        }
      }

      allContentfulHomepageB2Cases(sort: { order: ASC, fields: priority }) {
        nodes {
          id
          b2CaseTitle
          node_locale
        }
      }
    }
  `)

  const locales = useMemo(
    () => getNodesByLocale(data.allContentfulHomepageB2Cases.nodes, locale),
    [data.allContentfulHomepageB2Cases.nodes, locale]
  )

  const headline = useMemo(
    () => getNodeByLocale(data.allContentfulHomepageB1.nodes, locale),
    [data.allContentfulHomepageB1.nodes, locale]
  )

  return (
    <S.Container>
      <Indent lg={120} md={80} sm={64} />
      <div className="inner">
        <Title>{headline.b2Headline}</Title>
        <Indent lg={56} md={48} sm={32} />
        <div className="box">
          {locales.map(item => (
            <S.Case key={item.id}>
              <CheckIcon />
              <p>{item.b2CaseTitle}</p>
            </S.Case>
          ))}
        </div>
      </div>
      <Indent lg={80} md={0} sm={0} />
    </S.Container>
  )
}

export default Cases
