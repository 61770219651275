/* eslint-disable react/display-name */

import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import * as S from "./styled"

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <S.Headline>{children}</S.Headline>,
  },
  renderMark: {
    [MARKS.BOLD]: text => <span>{text}</span>,
  },
}

const Headline = ({ json }) => documentToReactComponents(json, options)

export default Headline
