import styled from "styled-components"

import { Title, media } from "src/uikit"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 1200px;
  }

  .box {
    display: flex;
    justify-content: center;
    padding: 0 16px;
  }

  ${Title} {
    text-align: center;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #0a1c61;
    max-width: 1000px;
  }

  @media ${media.tablet} {
    .box {
      flex-wrap: wrap;
    }
  }

  @media ${media.mobile} {
    .box {
      flex-direction: column;
      padding: 0 18px;
    }

    ${Title} {
      font-size: 26px;
      line-height: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`

export const Case = styled.div`
  max-width: 270px;
  width: 100%;
  background-color: #ffffff;
  border: 2px solid #dee0ed;
  box-sizing: border-box;
  border-radius: 24px;
  margin: 0 20px;
  padding: 24px 16px 32px 16px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  svg {
    margin-bottom: 16px;
  }

  p {
    font-weight: 500;
    font-size: 17px;
    line-height: 32px;

    letter-spacing: 0.5px;
    color: #0a1c61;
    margin: 0;
  }

  @media ${media.tablet} {
    margin: 20px !important;
  }

  @media ${media.mobile} {
    margin: 0 0 32px 0 !important;

    :last-child {
      margin-bottom: 0 !important;
    }
  }
`
