import React, { useMemo, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useLocale } from "src/common/hooks"
import { getNodeByLocale } from "src/common/getNodesByLocale"
import Button from "src/newUikit/Button"
import { Indent, RCTooltip } from "src/uikit"
import { getModalClickHandler } from "src/components/ModalsContainer/events"

import * as S from "./styled"
import Headline from "./components/Headline"
import VideoPlayer from "./components/VideoPlayer"
import BgEl1 from "./assets/bg-elem-1.svg"
import BgEl2 from "./assets/bg-elem-2.svg"

const Banner = () => {
  const locale = useLocale()
  const partnerContainer = useRef(null)

  const data = useStaticQuery(graphql`
    query Locales {
      allContentfulHomepageB1 {
        nodes {
          b1Headline {
            json
          }
          b1Video {
            file {
              url
              contentType
            }
          }
          videoPoster {
            file {
              url
            }
          }
          coSellPartnerDesktopLogo {
            file {
              url
            }
            description
          }
          coSellPartnerTabletLogo {
            file {
              url
            }
            description
          }
          coSellPartnerMobileLogo {
            file {
              url
            }
            description
          }
          coSellPartnerTooltipText

          cta1ButtonName
          cta1ButtonModal
          cta1ButtonModalTitle

          cta2ButtonName
          cta2ButtonModalTitle
          cta2ButtonModal

          node_locale
        }
      }
    }
  `)

  const node = useMemo(
    () => getNodeByLocale(data.allContentfulHomepageB1.nodes, locale),
    [data.allContentfulHomepageB1.nodes, locale]
  )

  const handleCta1Click = getModalClickHandler(node.cta1ButtonModal, {
    title: node.cta1ButtonModalTitle,
  })

  const handleCta2Click = getModalClickHandler(node.cta2ButtonModal, {
    title: node.cta2ButtonModalTitle,
  })

  return (
    <S.Container>
      <Indent lg={96} md={80} sm={40} />
      <div className="inner">
        <div className="text-container">
          <Headline json={node.b1Headline.json} />
          <div className="buttons-container">
            <Button
              color="cyan"
              url={node.cta1ButtonSlug}
              onClick={handleCta1Click}
            >
              {node.cta1ButtonName}
            </Button>
            <Button
              type="stroke"
              url={node.cta2ButtonSlug}
              onClick={handleCta2Click}
            >
              {node.cta2ButtonName}
            </Button>
          </div>
        </div>
        <div className="video-container">
          <VideoPlayer
            src={node.b1Video?.file?.url}
            contentType={node.b1Video?.file?.contentType}
            poster={node.videoPoster?.file?.url}
          />
          <S.Background />

          <S.PartnerContainer id="co-sell-partner" ref={partnerContainer}>
            <RCTooltip
              placement="top"
              type="info"
              overlay={<div>{node.coSellPartnerTooltipText}</div>}
              el={partnerContainer}
            >
              <div className="inner">
                <img
                  className="desktop lazyload"
                  data-src={node.coSellPartnerDesktopLogo?.file?.url}
                  alt={node.coSellPartnerLogo?.description}
                />
                <img
                  className="tablet lazyload"
                  data-src={node.coSellPartnerTabletLogo?.file?.url}
                  alt={node.coSellPartnerTabletLogo?.description}
                />
                <img
                  className="mobile lazyload"
                  data-src={node.coSellPartnerMobileLogo?.file?.url}
                  alt={node.coSellPartnerMobileLogo?.description}
                />
              </div>
            </RCTooltip>
          </S.PartnerContainer>
        </div>

        <BgEl1 className="bg-1" />
        <BgEl2 className="bg-2" />
      </div>
      <Indent lg={128} md={0} sm={0} />
    </S.Container>
  )
}

export default Banner
