import React, { useState } from "react"
import Layout from "../layout"
import SEO from "../seo"
import useContent from "../developers/useContent"
import { useHeaderColor } from "src/common/hooks"

import {
  Banner,
  Quote,
  Partners,
  Cases,
  Features,
  Testimonial,
  BottomCTA,
} from "src/components/HomePage"

import FAQ from "src/components/FAQ"
import ContactSalesModal from "src/developers/ContactSales"
import IntegrationGrid from "src/components/IntegrationGrid"
import CompactFeaturesSection from '../components/CompactFeaturesSection';

const SalesPage = ({ location }) => {
  const content = useContent()
  const { inverse, isChangeColor } = useHeaderColor({
    defaultInverse: true,
    elem: { selector: "#partners", inverse: false },
  })

  const headerColor = {
    primaryColor:
      "linear-gradient(255.03deg, #7785FF -0.91%, #6472EF 102.89%) #fff",
    secondaryColor: "#fff",
  }

  const [isContactSalesModalOpen, setIsContactSalesModalOpen] = useState(false)
  const handleModalClose = () => setIsContactSalesModalOpen(false)
  const handleModalOpen = () => setIsContactSalesModalOpen(true)

  return (
    <Layout
      location={location}
      headerColor={headerColor}
      headerInverse={inverse}
      headerIsChangeColor={isChangeColor}
      openModal={handleModalOpen}
    >
      <SEO
        title="Human-Like Voice AI for Sales and Customer Service Automation"
        description="Transform customer and sales interactions with Dasha's voice AI. Automate lead qualification, support, and more. Schedule a demo to see Dasha in action!"
        keywords="voice AI, human-like AI, Dasha AI, AI customer service, AI sales automation, lead qualification AI, appointment setting AI, customer support automation, sales AI, customer engagement AI, voice automation, AI call center, conversational AI, sales lead automation, call center AI, AI for customer satisfaction, business automation AI"
      />
      <Banner />
      <Quote />
      <Partners />
      <Cases />
      <Features />
      <CompactFeaturesSection content={content.features} />
      <Testimonial />
      <BottomCTA />
      <IntegrationGrid />
      <FAQ />
      <ContactSalesModal
        content={{ title: "Contact Sales" }}
        isOpen={isContactSalesModalOpen}
        onClose={handleModalClose}
      />
    </Layout>
  )
}

export default SalesPage
