import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useLocale } from "src/common/hooks"
import { getNodesByLocale } from "src/common/getNodesByLocale"
import { Title, Indent } from "src/uikit"
import Button from "src/newUikit/Button"
import { getModalClickHandler } from "src/components/ModalsContainer/events"

import AudioPlayer from "./components/AudioPlayer"
import Description from "./components/Description"
import * as S from "./styled"

const Features = () => {
  const locale = useLocale()
  const data = useStaticQuery(graphql`
    query {
      allContentfulHomepageB3Feature(sort: { order: ASC, fields: priority }) {
        nodes {
          id
          node_locale
          audioSample {
            file {
              url
              contentType
            }
          }
          audioTitle
          audioDuration
          ctaButtonTitle
          ctaButtonModal
          ctaButtonModalTitle
          description {
            json
          }
          title
          image {
            fluid {
              src
              srcSet
            }
            description
          }
        }
      }
    }
  `)

  const nodes = useMemo(
    () => getNodesByLocale(data.allContentfulHomepageB3Feature.nodes, locale),
    [data.allContentfulHomepageB3Feature.nodes, locale]
  )

  return (
    <>
      {nodes.map(
        ({
          id,
          image,
          audioSample,
          audioTitle,
          audioDuration,
          title,
          description,
          ctaButtonTitle,
          ctaButtonModal,
          ctaButtonModalTitle,
        }) => (
          <S.Container key={id}>
            <div className="inner">
              <div className="image-container">
                <img
                  className="lazyload"
                  data-src={image?.fluid?.src}
                  data-srcset={image?.fluid?.srcSet}
                  alt={image?.description}
                />
                <Indent lg={40} md={40} sm={40} />
                {audioSample && (
                  <div className="player-container">
                    <AudioPlayer
                      src={audioSample?.file?.url}
                      contentType={audioSample?.file?.contentType}
                      name={audioTitle}
                      duration={audioDuration}
                    />
                  </div>
                )}
              </div>
              <div className="text-container">
                <Title>{title}</Title>
                <Indent lg={16} md={16} sm={16} />
                {description && <Description json={description?.json} />}
                <Indent lg={24} md={24} sm={24} />
                <Button
                  inverse
                  color="purple"
                  size="small"
                  onClick={getModalClickHandler(ctaButtonModal, {
                    title: ctaButtonModalTitle,
                  })}
                >
                  {ctaButtonTitle}
                </Button>
              </div>
            </div>
          </S.Container>
        )
      )}
    </>
  )
}

export default Features
